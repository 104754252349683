<!-- 人力资源 -->
<template>
    <div class="human">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="员工工号">
                <el-input v-model="form.jobNumber" placeholder="请输入员工工号"></el-input>
            </el-form-item>
            <el-form-item label="人员姓名">
                <el-input v-model="form.fullName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="部门">
                <el-input v-model="form.department" placeholder="请输入部门"></el-input>
                <!-- <el-select v-model="form.department" placeholder="请选择" :clearable="true">
                    <el-option
                  v-for="item in choose"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.stateChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

        </el-form>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button>
                <!-- <el-button size="small" class="daochu" @click="exportOrderData">
                    导出
                </el-button> -->
            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:98%;flex: 1;" id="out-table"
         height="650" @selection-change="checkAll" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="jobnumber" sortable="custom" label="员工工号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="status" sortable="custom" label="状态" align="center" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">在职</span>
                    <span v-else-if="scope.row.status==2">新人</span>
                    <span v-else-if="scope.row.status==3">兼职</span>
                    <span v-else="scope.row.status==4">离职</span>
                </template>
            </el-table-column>
            <el-table-column prop="fullname" sortable="custom" label="人员姓名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="englishname" sortable="custom" label="英文名字" align="center" width="120">
            </el-table-column>
            <el-table-column prop="department" sortable="custom" label="所在部门" align="center" width="180">
            </el-table-column>
            <el-table-column prop="position" sortable="custom" label="岗位" align="center" width="80">
            </el-table-column>
            <el-table-column prop="mobile" sortable="custom" label="手机号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="labor_contract" sortable="custom" label="合同签订" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.labor_contract==1">签订合同</span>
                    <span v-else-if="scope.row.labor_contract==2">未签合同</span>
                    <span v-else="scope.row.labor_contract==3">不确定</span>
                </template>
            </el-table-column>
            <el-table-column prop="office_space" sortable="custom" label="办公场地" align="center" width="200">
            </el-table-column>
            <el-table-column prop="entry_date" sortable="custom" label="入职日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="quit_date" sortable="custom" label="离职日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="census_register" sortable="custom" label="户籍所在地" align="center" width="180">
            </el-table-column>
            <el-table-column prop="native_place" sortable="custom" label="籍贯" align="center" width="80">
            </el-table-column>
            <el-table-column prop="is_marry" sortable="custom" label="婚否" align="center" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_marry==1">已婚</span>
                    <span v-else="scope.row.is_marry==2">未婚</span>
                </template>
            </el-table-column>
            <el-table-column prop="residence_permit" sortable="custom" label="居住证" align="center" width="100">
            </el-table-column>
            <el-table-column prop="education" sortable="custom" label="文化程度" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_aid" sortable="custom" label="录入人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom" label="录入时间" align="center" width="200">
            </el-table-column>
            <el-table-column prop="save_aid" sortable="custom" label="修改人" align="center" width="100">
            </el-table-column>
            <el-table-column prop="save_time" sortable="custom" label="最近调整时间" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" fixed="right" align="center" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;" 
                    @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="delet(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
            @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    jobNumber: "", //工号
                    fullName: "", //人员姓名
                    choose: [], //部门选择
                    department: "", //部门
                    stateChoose: [{
                            label: "在职",
                            value: 1
                        },
                        {
                            label: "新人",
                            value: 2
                        },
                        {
                            label: "兼职",
                            value: 3
                        },
                        {
                            label: "离职",
                            value: 4
                        },
                    ], //状态选择
                    condition: "", //状态 
                },
                currentPage: 1,
                currentLimit:20,
                total: 0,
                tableData: [],
                arr:[]
            }
        },
        created() {   
            this.getList()            
        },
        activated() {
           // console.log(1); 
           this.getList()
        },
        methods: {
            empty(){//重置
                this.form.jobNumber=""//工号
                this.form.fullName=""//人员姓名
                this.form.department=""//部门
                this.form.condition=""//状态
                this.getList()
            },
            getList(){//获取列表
                this.$request({url:'/api/humanresources/list',method:'POST',data:{
                    jobnumber: this.form.jobNumber,
                    fullname: this.form.fullName,
                    department: this.form.department,
                    status: this.form.condition,
                    limit:this.currentLimit,
                    page:this.currentPage,
                    sort:this.arr
                }}).then(res=>{
                    console.log(res)
                    this.tableData = res.data.list
                    this.total = res.data.count
                })
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.currentLimit=val
                this.getList()
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage=val
                this.getList()
            },
            delet(index, row) {//删除
                console.log(row)
                this.$confirm('此操作将永久删除该数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    acncelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({url:'/api/humanresources/del',method:'POST',data:{
                        id: row.id
                    }}).then(res=>{
                        if(res.code==1){
                            this.$message({
                              message: res.msg,
                              type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addHuman"
                })
            },
            edit(index, row) { //编辑
                this.$router.push({
                    path: "editHuman",
                    query:{
                        id:row.id
                    }
                })
            },
            query(){
                this.currentPage=1
                this.getList()
            },
            // 全选
            checkAll(selection){
                this.chosen=[]
                // 获取勾选的数据
                console.log(selection)
            },
            
            exportOrderData(){//导出
                console.log('点击成功')
            },
            // 姓名排序
            fn2(a, b){
                return a.fullname.localeCompare(b.fullname)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                this.getList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .human {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    // .el-container {
    //     width: 100%;
    //     height: 100%;
    //     overflow: hidden;
    // }
    /* 输入框 */
    .form {
        padding-top: 34px;
    }

    #form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    #form ::v-deep .el-form-item__label {
        color: #000000;
    }

    #form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    // 按钮
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }
    .daochu{
        padding-left: 30px;
        background: url(../../assets/export.png) 8px 5px no-repeat #FF7300;
        color: #FFFFFF;
    }
    //表格
    .el-table {
        margin: 20px;
    }


    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 28px;
    }


    // 分页
    .el-pagination {
        margin-bottom: 20px;
        margin-left: 20px;
        // margin-top: 20px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
