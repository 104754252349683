import { render, staticRenderFns } from "./Human.vue?vue&type=template&id=69d1d3e4&scoped=true&"
import script from "./Human.vue?vue&type=script&lang=js&"
export * from "./Human.vue?vue&type=script&lang=js&"
import style0 from "./Human.vue?vue&type=style&index=0&id=69d1d3e4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69d1d3e4",
  null
  
)

export default component.exports